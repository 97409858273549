import $ from "jquery";

export default function utils() {
  $("html").removeClass("no-js");

  $("a[href^=http], a.external").on("click", function () {
    window.open(this.href);
    return false;
  });
}
