import $ from "jquery";

export default function skiplinks() {
  function closeMenu() {
    $("body").removeClass("block-site");
    $(".hamburger").removeClass("open").attr("aria-expanded", false);
    $(".navigation").removeClass("navigation--open");
    $(".navigation__btn").attr("aria-expanded", false);
    $(".navigation__btn").parents(".navigation__item").removeClass("navigation__item--open");
  }

  function init() {
    $(".skiptocontent").on("click", function (e) {
      e.preventDefault();
      closeMenu();
      const focusUtils = document.getElementById("utils");
      if (focusUtils) {
        focusUtils.scrollIntoView();
        focusUtils.tabIndex = 0;
        focusUtils.focus();
      } else {
        const focusBreadcrumb = document.getElementById("breadcrumb");
        focusBreadcrumb.scrollIntoView();
        focusBreadcrumb.tabIndex = 0;
        focusBreadcrumb.focus();
      }
    });

    $(".skiptonav").on("click", function (e) {
      e.preventDefault();
      closeMenu();
      const focusNav = document.getElementById("navigation");
      focusNav.scrollIntoView();
      focusNav.tabIndex = 0;
      focusNav.focus();
    });

    $(".skiptofooter").on("click", function (e) {
      e.preventDefault();
      closeMenu();
      const focusFooter = document.getElementById("footer");
      focusFooter.scrollIntoView();
      focusFooter.tabIndex = 0;
      focusFooter.focus();
    });
  }

  $(document).ready(function () {
    if ($(".skiplinks").length > 0) {
      init();
    }
  });
}
