import $ from "jquery";

export default function hamburger() {
  function init() {
    $(".hamburger").on("click", function () {
      $(".navigation__item").removeClass("navigation__item--open");

      if (!$(this).hasClass("open")) {
        $("body").addClass("block-site");
        $(this).addClass("open").attr("aria-expanded", true);
        $(".navigation").addClass("navigation--open");
      } else {
        $("body").removeClass("block-site");
        $(this).removeClass("open").attr("aria-expanded", false);
        $(".navigation").removeClass("navigation--open");
      }
    });

    $(".navigation__btn").on("click", function () {
      if (!$(this).parents(".navigation__item").hasClass("navigation__item--open")) {
        $(this).attr("aria-expanded", true);
        $(this).parents(".navigation__item").addClass("navigation__item--open");
      } else {
        $(this).attr("aria-expanded", false);
        $(this).parents(".navigation__item").removeClass("navigation__item--open");
      }
    });
  }

  $(document).ready(function () {
    if ($(".hamburger").length > 0) {
      init();
    }
  });
}
