import $ from "jquery";

export default function accordion() {
  function init() {
    if ($(".tabs-nav").length > 0) {
      $(".tabs-nav .tabs-nav__item:first-child").addClass("tabs-nav__item--active");
      $(".tab-wrapper .tab-content:first-child").addClass("tab-content--active");
      //tab
      $(".tabs-nav .tabs-nav__item .tabs-nav__link").on("click", function (e) {
        e.preventDefault();
        let idTab = $(this).attr("href");

        $(".tabs-nav .tabs-nav__item").removeClass("tabs-nav__item--active");
        $(this).parents(".tabs-nav__item").addClass("tabs-nav__item--active");

        $(".tab-content").removeClass("tab-content--active");
        $(idTab).addClass("tab-content--active");
      });
    }

    if ($(".box-accordion").length > 0) {
      $(".box-accordion .box-accordion__title").on("click", function (e) {
        if (!$(this).parents(".box-accordion").hasClass("open")) {
          $(this).parents(".box-accordion").addClass("open");
        } else {
          $(this).parents(".box-accordion").removeClass("open");
        }
      });
    }
  }

  $(document).ready(function () {
    if ($(".box-accordion").length > 0) {
      init();
    }
  });
}
