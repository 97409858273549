// import colorScheme from "./color-scheme";
import skiplinks from "./skiplinks";
import utils from "./utils";
import slider from "./slider";
import footer from "./footer";
import hamburger from "./hamburger";
import navInt from "./navigation-internal";
import accordion from "./box-accordion";

// colorScheme();
skiplinks();
utils();
slider();
footer();
hamburger();
navInt();
accordion();
