import $ from "jquery";

export default function footer() {
  function init() {
    $(".footer .footer__label").on("click", function () {
      if (!$(this).parents(".footer__item").hasClass("open")) {
        // $(this).attr("aria-expanded", true);
        $(this).parents(".footer__item").addClass("open");
      } else {
        // $(this).attr("aria-expanded", false);
        $(this).parents(".footer__item").removeClass("open");
      }
    });
  }

  $(document).ready(function () {
    if ($(".footer .footer__label").length > 0) {
      init();
    }
  });
}
