import $ from "jquery";

export default function navInt() {
  function init() {
    $(".navigation-internal .navigation-internal__toggle").on("click", function () {
      if (
        !$(this).parents(".navigation-internal__item").hasClass("navigation-internal__item--open")
      ) {
        $(this).attr("aria-expanded", true);
        $(this).parents(".navigation-internal__item").addClass("navigation-internal__item--open");
      } else {
        $(this).attr("aria-expanded", false);
        $(this)
          .parents(".navigation-internal__item")
          .removeClass("navigation-internal__item--open");
      }
    });
  }

  $(document).ready(function () {
    if ($(".navigation-internal").length > 0) {
      init();
    }
  });
}
