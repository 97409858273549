import $ from "jquery";
import slick from "@accessible360/accessible-slick";

export default function slider() {
  function hero() {
    $(".slider-hero").slick({
      dots: false,
      arrows: true,
      prevArrow:
        '<button type="button" class="slick-prev slick-arrow"><span class="slick-prev-icon" aria-hidden="true"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="ico-svg"><path fill="currentColor" d="M20.7 267.3c-6.2-6.2-6.2-16.4 0-22.6l192-192c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6L54.6 256 235.3 436.7c6.2 6.2 6.2 16.4 0 22.6s-16.4 6.2-22.6 0l-192-192z"/></svg></span><span class="slick-sr-only">Previous</span></button>',
      nextArrow:
        '<button type="button" class="slick-next slick-arrow"><span class="slick-next-icon" aria-hidden="true"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="ico-svg"><path fill="currentColor" d="M299.3 244.7c6.2 6.2 6.2 16.4 0 22.6l-192 192c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6L265.4 256 84.7 75.3c-6.2-6.2-6.2-16.4 0-22.6s16.4-6.2 22.6 0l192 192z"/></svg></span><span class="slick-sr-only">Next</span></button>',
      playIcon:
        '<span class="slick-play-icon" aria-hidden="true"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="ico-svg"><path fill="currentColor" d="M48 96c-8.8 0-16 7.2-16 16V400c0 8.8 7.2 16 16 16H96c8.8 0 16-7.2 16-16V112c0-8.8-7.2-16-16-16H48zM0 112C0 85.5 21.5 64 48 64H96c26.5 0 48 21.5 48 48V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112zM224 96c-8.8 0-16 7.2-16 16V400c0 8.8 7.2 16 16 16h48c8.8 0 16-7.2 16-16V112c0-8.8-7.2-16-16-16H224zm-48 16c0-26.5 21.5-48 48-48h48c26.5 0 48 21.5 48 48V400c0 26.5-21.5 48-48 48H224c-26.5 0-48-21.5-48-48V112z"/></svg></span>',
      pauseIcon:
        '<span class="slick-pause-icon" aria-hidden="true"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="ico-svg"><path fill="currentColor" d="M56.3 66.3c-4.9-3-11.1-3.1-16.2-.3s-8.2 8.2-8.2 14V432c0 5.8 3.1 11.1 8.2 14s11.2 2.7 16.2-.3l288-176c4.8-2.9 7.7-8.1 7.7-13.7s-2.9-10.7-7.7-13.7l-288-176zM24.5 38.1C39.7 29.6 58.2 30 73 39L361 215c14.3 8.7 23 24.2 23 41s-8.7 32.2-23 41L73 473c-14.8 9.1-33.4 9.4-48.5 .9S0 449.4 0 432V80C0 62.6 9.4 46.6 24.5 38.1z"/></svg></span>',
      infinite: true,
      autoplay: true,
      autoplaySpeed: 5000,
      slidesToShow: 1,
      slidesToScroll: 1,
    });
  }

  function events() {
    $(".slider-events").slick({
      arrows: true,
      appendArrows: $(".slider-controls-events"),
      prevArrow:
        '<button type="button" class="slick-prev slick-arrow"><span class="slick-prev-icon" aria-hidden="true"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="ico-svg"><path fill="currentColor" d="M20.7 267.3c-6.2-6.2-6.2-16.4 0-22.6l192-192c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6L54.6 256 235.3 436.7c6.2 6.2 6.2 16.4 0 22.6s-16.4 6.2-22.6 0l-192-192z"/></svg></span><span class="slick-sr-only">Previous</span></button>',
      nextArrow:
        '<button type="button" class="slick-next slick-arrow"><span class="slick-next-icon" aria-hidden="true"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="ico-svg"><path fill="currentColor" d="M299.3 244.7c6.2 6.2 6.2 16.4 0 22.6l-192 192c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6L265.4 256 84.7 75.3c-6.2-6.2-6.2-16.4 0-22.6s16.4-6.2 22.6 0l192 192z"/></svg></span><span class="slick-sr-only">Next</span></button>',
      dots: true,
      appendDots: $(".slider-controls-events .dots"),
      infinite: false,
      autoplay: false,
      slidesToShow: 3,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 767,
          settings: {
            centerMode: true,
            slidesToShow: 1,
            centerPadding: "15%",
          },
        },
      ],
    });
  }

  $(document).ready(function () {
    if ($(".slider-hero").length > 0) {
      hero();
    }

    if ($(".slider-events").length > 0) {
      events();
    }
  });
}
